import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import axios from 'axios'
import { Row, Col, Gap, Text, Section, Title, Button, VH, SEO, TextField } from '../components'
import { up } from '../lib/styles'
import Link from '../components/Link'
import links from '../lib/links'

const DownloadLink = styled.a`
  text-transform: uppercase;
  color: white;
  font-size: 20px;
  font-weight: 700;
  background: #007ef9;
  display: block;
  cursor: pointer;
  padding: 16px 20px;
  border: 1px solid #006cd3;

  transition: 0.2s;
  &:hover {
    background: #006cd3;
  }

  ${up('mobile')} {
    font-size: 24px;
    padding: 20px 24px;
  }
`

const CONTENT_WIDTH = '1700px'
// const CONTENT_WIDTH = '1435px'

class Vr extends Component {
  render() {
    const {
      location: { pathname },
      data: { vrImage1, vrImage2 },
    } = this.props

    return (
      <>
        <Gap.Top />
        <SEO
          title="VR | Zuri"
          description="We are developing a modern private aircraft with vertical takeoff and landing. This will allow you to affordably travel from city to city at distances of up to 700 km apart."
          pathname={pathname}
        />
        <Section background="black" width={CONTENT_WIDTH}>
          <Gap gap="40px" mobileGap="40px" />

          <Title.Big innerAs="h1" textColor="white" style={{ textTransform: 'uppercase' }}>
            Experience Zuri 2.0 in fullsize in your living room!
          </Title.Big>
        </Section>

        <Gap gap="32px" mobileGap="24px" />
        <Img fluid={vrImage1.image.fluid} />
        <Gap gap="80px" mobileGap="40px" />

        <Section background="black" width={CONTENT_WIDTH}>
          <Row>
            <Col alignItems="center">
              <Row>
                <DownloadLink href="https://www.meta.com/experiences/7485815678101871/" target="_blank">
                  Download here using your meta quest
                </DownloadLink>
              </Row>
              <Gap gap="20px" mobileGap="20px" />

              <Text.Small fontSize="16px" style={{ color: 'white' }}>
                Requires Meta Quest 2 or better. Meta Quest 3 is recommended.
              </Text.Small>
            </Col>
          </Row>

          <Gap gap="120px" mobileGap="60px" />

          <Title.Big innerAs="h2" textColor="white" style={{ textTransform: 'uppercase' }}>
            Unique VR simulator for five people
          </Title.Big>
        </Section>

        <Gap gap="32px" mobileGap="24px" />
        <Img fluid={vrImage2.image.fluid} />
        <Gap gap="80px" mobileGap="32px" />

        <Section background="black" width={CONTENT_WIDTH}>
          <Text style={{ color: 'white', maxWidth: '770px' }}>
            For events such as Aero 2023 Zuri presented its unique 5-person VR solution. Where a
            pilot with a VR headset can control the airplane and 4 additional passengers can
            experience that flight in VR as well
          </Text>
          <Gap gap="80px" mobileGap="40px" />
        </Section>
      </>
    )
  }
}

export default Vr

export const pageQuery = graphql`
  query {
    vrImage1: file(relativePath: { eq: "images/vr/vr1.v2.jpg" }) {
      ...FullHdImage
    }
    vrImage2: file(relativePath: { eq: "images/vr/vr2.jpg" }) {
      ...FullHdImage
    }
  }
`
